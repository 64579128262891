<template>
    <div class="level_index level_indexI">

        <div class="search">
            <el-form :inline="true">
                <el-form-item prop="title">
                    <el-input clearable @keydown.enter.native="search()" v-model="searchtitle"
                        placeholder="请输入单位名称或姓名" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="search()">
                        <i class="fa fa-search" aria-hidden="true"></i>
                        查询
                    </el-button>

                    <el-button type="primary" @click="toSearch" v-if="getPurviewNew('sys:admin:add')">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                        添加人员
                    </el-button>

                    <!-- <el-button class="block" @click="del">批量删除</el-button> -->

                    <!-- <el-button class="block" @click="AlonePower">
                        <i class="el-icon-monitor"></i>
                        独立权限
                    </el-button>
                    <el-button class="block" @click="resetPWD">
                        <i class="el-icon-lock"></i>
                        重置密码
                    </el-button> -->

                </el-form-item>
            </el-form>
        </div>

        <!-- <Fanfu-Table class="FanfuTableBox" v-model="select" :data="data" :search-height="50" :loading="loading"
            :get-list="getList" stripe border> -->

        <!-- :cell-style="autoTableCell" -->
        <el-table class="el-tableII FanfuTableBox" :data="data.list" stripe height="100%" border ref="multipleTable"
            fit>


            <el-table-column show-overflow-tooltip type="selection" min-width="40" align="center" fixed="left" />
            <el-table-column show-overflow-tooltip label="序号" type="index" align="center" fixed="left" />

            <!-- <el-table-column show-overflow-tooltip    prop="ID" label="ID" align="left" min-width="80" /> -->

            <el-table-column show-overflow-tooltip prop="img" label="头像" align="center" min-width="70">
                <template slot-scope="scope">
                    <img src="" alt="" v-if="scope.row.avatar" v-bind:src="GLOBAL.BASE_URL+ scope.row.avatar"
                        class="CoverImage">
                </template>
            </el-table-column>


            <el-table-column show-overflow-tooltip prop="dep_name" label="单位名称" align="left" min-width="150" />


            <!-- <el-table-column show-overflow-tooltip prop="username" label="账号" align="left" /> -->
            <el-table-column show-overflow-tooltip v-if="UserInfoData.is_admin==1" prop="" label="账号" align="left"
                min-width="50">
                <template slot-scope="scope">
                    {{scope.row.username}}
                </template>
            </el-table-column>




            <el-table-column show-overflow-tooltip prop="realname" label="真实姓名" align="left" min-width="150" />
            <!-- <el-table-column show-overflow-tooltip    prop="nickname" label="昵称" align="left" min-width="70" /> -->
            <el-table-column show-overflow-tooltip prop="" label="性别" align="center" min-width="50">
                <template slot-scope="scope">
                    <span v-if="scope.row.gender=='1'">男</span>
                    <span v-if="scope.row.gender=='2'">女</span>
                    <span v-if="scope.row.gender=='3'">未知</span>
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="level_name" label="科室" align="left" min-width="150" />

            <el-table-column show-overflow-tooltip prop="position_name" label="岗位" align="left" min-width="150" />

            <el-table-column show-overflow-tooltip prop="mobile" label="手机号码" align="center" min-width="150" />
            <!-- <el-table-column show-overflow-tooltip    prop="email" label="邮箱地址" align="center" min-width="100" /> -->
            <!-- <el-table-column show-overflow-tooltip    prop="identity" label="身份证号" align="center" min-width="100" /> -->
            <!-- <el-table-column show-overflow-tooltip    prop="address" label="地址" align="center" min-width="100" /> -->


            <el-table-column show-overflow-tooltip prop="isAdmin" label="管理员" align="center" min-width="60">
                <template slot-scope="scope">
                    <el-button type="primary" round v-if="scope.row.is_admin=='1'" size="mini"> 是 </el-button>
                    <el-button type="danger" round v-else size="mini"> 否 </el-button>
                </template>
            </el-table-column>


            <el-table-column show-overflow-tooltip prop="" label="状态" align="center" width="90">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.status" :active-value="1" active-color="#5BD995" active-text="正常"
                        :inactive-value="2" inactive-color="#D9D9D9" inactive-text="禁用" class="switchSelfBox"
                        style="pointer-events: painted;" @change="switchStatus(scope.row)">
                    </el-switch>
                </template>
            </el-table-column>

            <<!-- el-table-column prop="sealing_number" label="封箱签名数量" align="center" min-width="100" />
            <el-table-column show-overflow-tooltip prop="ware_itemcate" label="入库签名数量" align="center" min-width="100" />
            -->

            <!-- <el-table-column show-overflow-tooltip    prop="times" label="登录次数" align="center" min-width="70" /> -->

            <!-- min-width="400" -->
            <!-- min-width="330" -->
            <el-table-column show-overflow-tooltip label="操作"
                :width="autoTableWidth(['sys:admin:copy','sys:admin:edit','sys:admin:drop','sys:admin:permission','sys:admin:resetPwd'],100)"
                align="center">
                <template slot-scope="scope">
                    <div class="operate">


                        <!--  <el-button type="warning" @click="AlonePower(scope.row)">
                            独立权限
                        </el-button>
                        <el-button type="primary" @click="resetPWD(scope.row)">
                            重置密码
                        </el-button> -->

                        <!-- <el-button type="primary" @click="view(scope.row)">
                            查看
                        </el-button> -->


                        <el-button style="width: 70px;" type="" @click="copy(scope.row)"
                            v-if="getPurviewNew('sys:admin:copy')">
                            复制
                        </el-button>


                        <el-button style="width: 70px;" type="primary" @click="edit(scope.row)"
                            v-if="getPurviewNew('sys:admin:edit')">
                            编辑
                        </el-button>
                        <el-button style="width: 70px;" type="danger" @click="del(scope.row)"
                            v-if="getPurviewNew('sys:admin:drop')">
                            删除
                        </el-button>
                        <el-button type="success" style="background-color: #00CFE8;border-color:transparent"
                            @click="AlonePower(scope.row)" v-if="getPurviewNew('sys:admin:permission')">
                            独立权限
                        </el-button>
                        <el-button type="warning" @click="resetPWD(scope.row)"
                            v-if="getPurviewNew('sys:admin:resetPwd')">
                            重置密码
                        </el-button>




                    </div>

                </template>
            </el-table-column>

        </el-table>
        <!-- </Fanfu-Table> -->


        <el-pagination layout="total, prev, pager, next, jumper" background :current-page.sync="data.page"
            :page-size="data.size" :total="data.total" @current-change="handleCurrentChange" />



        <!-- <Search ref="searchRef" /> -->
        <!-- v-model="search" @refresh="getList(data.size)" -->


        <AlonePower ref="AlonePowerRef" @refresh="getAdminList()" />

        <StaffListAdd ref="StaffListAdd" />

        <rePWDSearch ref="rePWDSearch" />



    </div>
</template>

<script>
    // import Search from './components/StaffSearch'

    import AlonePower from './components/AlonePower'

    import StaffListAdd from './components/StaffListAdd.vue'

    import rePWDSearch from './components/rePWDSearch.vue'



    import resizeDetector from 'element-resize-detector'

    export default {
        components: {
            // Search,
            AlonePower,
            StaffListAdd,
            rePWDSearch
        },
        data() {
            return {
                // search: {
                //     title: '',
                //     name2: '',
                //     name3: '',
                //     name4: '',
                //     name5: '',
                //     name6: ''
                // },
                // loading: false,
                // select: [],

                // data: {
                //     list,
                //     // num: "ID",
                //     page: 1,
                //     size: 15,
                //     total: list.length
                // },

                data: {
                    list: [],
                    page: 1,
                    size: 15,
                    total: 0,
                },

                searchtitle: ""

            }
        },

        mounted() {
            this.modifyLayout();
        },

        created() {


            // var routes = JSON.parse(localStorage.getItem('user')).menu

            // var purview_List = routes.filter(x => {
            //     return x.name == "员工管理"
            // })[0].children.filter(x => {
            //     return x.name == "人员列表"
            // })[0].children.filter(x => {
            //     return x.name == "列表"
            // })[0]





            this.modifyLayout();

            // this.getList()

            this.getAdminList()
        },

        watch: {
            searchtitle(newValue, oldValue) {
                if (oldValue.length > 0 && newValue.length == 0) {

                    this.search()

                }

            }
        },

        methods: {

            getAdminList() {

                this.$http.get('/api/admin', {
                    title: this.searchtitle,
                    page_size: this.data.size,
                    page: this.data.page

                }).then((res) => {
                    console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
                    if (res.code == 200) {
                        // this.setMedicalWasteItem(res.data.results)
                        // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", this.MedicalWasteItem);

                        this.data.list = res.data.data
                        this.data.total = res.data.total

                        setTimeout(() => {
                            this.modifyLayout();
                        }, 1100)

                    }

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);

                    }
                }).catch((err) => {
                    console.log(err)
                });
            },


            search() {

                // searchtitle
                this.data.page = 1
                this.getAdminList()
            },




            // getList(size) {

            //     if (size) {
            //         this.data.page = 1
            //         this.data.size = size
            //     }
            //     this.loading = true
            //     this.select = []
            //     this.$nextTick(() => {
            //         const start = this.data.size * (this.data.page - 1)
            //         const end = start + this.data.size
            //         this.data.total = list.length
            //         const data = []
            //         for (let i = start; i < end; i++) {
            //             if (list[i]) {
            //                 data.push(list[i])
            //             }
            //         }
            //         this.data.list = data
            //         setTimeout(() => {
            //             this.loading = false
            //         }, 1000)

            //         setTimeout(() => {
            //             console.log("++++++++++++++++++++++++++++++++")

            //             this.modifyLayout();
            //         }, 1100)

            //     })

            // },


            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);

                this.data.page = val

                this.getAdminList()
            },



            view(row) {
                console.log(row)
            },
            edit(row) {
                console.log(row)
                this.$refs.StaffListAdd.edit(row)

            },

            copy(row) {
                console.log(row)


                this.$refs.StaffListAdd.copy(row)


            },





            switchStatus(row) {

                // console.log(row);

                // return

                let a = row.status == 1 ? 2 : 1

                this.$http.put('/api/admin/setStatus/' + row.id, {
                    status: row.status
                }).then((res) => {
                    // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
                    if (res.code != 200) { //请求错误
                        row.status = a
                        this.$message.error(res.msg);
                    }
                    if (res.code == 200) {
                        this.$message.success(res.msg);
                        // this.close()
                    }
                }).catch((err) => {
                    console.log(err)
                });


            },

            del(row) {
                console.log(row)

                const h = this.$createElement;
                this.$msgbox({
                    title: '删除',
                    message: h('p', null, [
                        h('span', null, '该操作将删除所选数据，您确定要删除吗？删除后将无法恢复！'),

                    ]),
                    showCancelButton: true,
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = '执行中...';




                            this.$http.del('/api/admin/' + row.id).then((res) => {
                                console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                                if (res.code == 200) {
                                    this.getAdminList()
                                }

                                if (res.code != 200) { //请求错误
                                    this.$message.error(res.msg);
                                }

                                // setTimeout(() => {
                                done();
                                setTimeout(() => {
                                    instance.confirmButtonLoading = false;
                                }, 300);
                                // }, 3000);

                            }).catch((err) => {
                                console.log(err)
                            });


                            // setTimeout(() => {
                            //     done();
                            //     setTimeout(() => {
                            //         instance.confirmButtonLoading = false;
                            //     }, 300);
                            // }, 3000);



                        } else {
                            done();
                        }
                    }
                }).then(action => {
                    // this.$message({
                    //     type: 'info',
                    //     message: 'action: ' + action
                    // });
                });
            },


            toSearch() {
                this.$refs.StaffListAdd.open()

            },



            resetPWD(row) {

                this.$refs.rePWDSearch.rePWD(row)



            },

            AlonePower(row) {
                // this.$refs.AlonePowerRef.open()


                this.$refs.AlonePowerRef.edit(row)

            },


            modifyLayout() {
                const erd = resizeDetector();

                erd.listenTo(document.getElementsByClassName("search"), element => {
                    const width = element.offsetWidth;
                    const height = element.offsetHeight;


                    var a = 141 + 60 - 35 + height - 30;
                    if ($(".level_indexI .FanfuTableBox")[0]) {


                        $(".level_indexI .FanfuTableBox")[0].setAttribute("style", 'height: calc(100vh - ' + a +
                            'px) !important');
                    }

                    var b = 141 + 60 + 33 + height - 30;
                    if ($(".level_indexI .el-table")[0]) {


                        $(".level_indexI .el-table")[0].setAttribute("style", 'height: calc(100vh - ' + b +
                            'px) !important');
                    }


                });

            }

        },






    }
</script>

<style lang="scss" scoped>


</style>