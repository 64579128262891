<template>
    <el-dialog :close-on-click-modal="false" title="修改密码" :visible.sync="show" width="500px" class="selfInputBox">

        <el-form ref="form" label-width="80px">

            <el-form-item label="新密码" prop="">
                <el-input v-model="userInfo.password" type="password" placeholder="请输入密码，至少6位" />
            </el-form-item>


        </el-form>

        <span slot="footer">
            <div class="buttons">
                <el-button type="primary" @click="save">保存</el-button>
                <!-- <el-button @click="$refs.form.resetFields()">重置</el-button> -->
            </div>
        </span>

    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                show: false,
                state: true,
                userInfo: {},
            }
        },
        watch: {
            show(value) {
                if (this.show) {


                    this.userInfo.password = ""

                } else {
                    this.userInfo = {}
                }
            },
        },

        methods: {
            open() {
                this.show = true
            },
            rePWD(row) {
                this.userInfo = {
                    ...row
                }
                this.open()
            },
            save() {

                console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", this.userInfo.password);

                if (this.userInfo.password == undefined || this.userInfo.password == "" || this.userInfo.password
                    .length < 6) {
                    this.$message.error("请输入密码，至少6位");
                    return
                }


                const h = this.$createElement;
                this.$msgbox({
                    title: '重置密码',
                    message: h('p', null, [
                        h('span', null, '是否确定要重置密码？'),

                    ]),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = '执行中...';


                            // setTimeout(() => {
                            //     done();
                            //     setTimeout(() => {
                            //         instance.confirmButtonLoading = false;
                            //     }, 300);
                            // }, 3000);


                            this.$http.put('/api/admin/setPassword/' + this.userInfo.id, {

                                password: this.userInfo.password

                            }).then((res) => {
                                console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                                if (res.code == 200) {
                                    this.$message.success(res.msg);

                                    this.close()
                                }

                                if (res.code != 200) { //请求错误
                                    this.$message.error(res.msg);
                                }

                                // setTimeout(() => {
                                done();
                                setTimeout(() => {
                                    instance.confirmButtonLoading = false;
                                }, 300);
                                // }, 3000);

                            }).catch((err) => {
                                console.log(err)
                            });



                        } else {
                            done();
                        }
                    }
                }).then(action => {
                    // this.$message({
                    //     type: 'info',
                    //     message: 'action: ' + action
                    // });
                });

            },

            close() {
                this.show = false
            },
        }
    }
</script>