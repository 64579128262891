<template>
    <el-dialog :close-on-click-modal="false" title="独立权限" :visible.sync="show" width="500px" class="selfInputBox">

        <div class="boxThree">

            <!-- default-expand-all 是否默认展开所有节点 -->
            <!-- check-strictly 是否严格的遵循父子不互相关联的做法，默认为 false -->
            <!-- check-strictly -->
            <el-tree :data="power" show-checkbox node-key="id" ref="tree" highlight-current :props="defaultProps"
                :default-expanded-keys="selectArray" :default-checked-keys="selectArray"
                @check-change="handleCheckChange">
                <!-- :check-strictly="true" -->
            </el-tree>

        </div>

        <span slot="footer">
            <div class="buttons">
                <el-button type="primary" @click="save">保存</el-button>
                <!-- <el-button @click="$refs.form.resetFields()">重置</el-button> -->
            </div>
        </span>

    </el-dialog>
</template>

<script>
    export default {

        data() {
            return {
                show: false,
                formShow: false,

                userInfo: {},

                power: [],
                selectArray: [],
                selectArrayID: [],
                defaultProps: {
                    children: 'children',
                    label: 'name'
                },


            }
        },
        watch: {

            show(value) {
                if (this.show) {

                    // if (this.power.length == 0) {
                    this.getPower()
                    // }

                } else {
                    this.power = []
                    this.selectArray = []
                    this.selectArrayID = []

                }
            },

        },
        methods: {

            getPower() {
                this.$http.get('/api/menu/tree').then((res) => {
                    if (res.code == 200) {
                        this.power = res.data

                    }

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);
                    }
                }).catch((err) => {
                    console.log(err)
                });
            },


            open() {
                this.formShow = true
                this.show = true
            },


            refresh() {
                this.show = false
                this.$emit('refresh')
                setTimeout(() => {
                    this.formShow = false
                }, 1000)
            },


            save() {

                // 父级id获取到
                const checkedkeys = this.$refs.tree.getHalfCheckedKeys()
                //子节点的权限id
                const id = this.$refs.tree.getCheckedKeys()
                // 重组
                const ids = checkedkeys.concat(id)


                // type 类型：1角色 2用户
                // type_id type对应的值：角色id、用户id
                // menu_id 菜单ID

                var a = {
                    type: 2,
                    type_id: this.userInfo.id,
                    // menu_id: this.selectArray.toString(),
                    menu_id: ids.toString(),

                }

                this.$http.post('/api/adminrom', a).then((res) => {
                    console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);

                    }
                    if (res.code == 200) {

                        this.refresh()

                    }
                }).catch((err) => {
                    console.log(err)
                });


            },


            edit(row) {
                this.userInfo = {
                    ...row
                }

                this.$http.get('/api/adminrom/' + this.userInfo.id).then((res) => {
                    console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);
                    }

                    if (res.code == 200) {

                        setTimeout(() => {

                            this.filterFun(res.data)

                        }, 500)

                        this.open()

                    }
                }).catch((err) => {
                    console.log(err)
                });

                // this.selectArray = []
                // if (res.data) {
                //     this.selectArray = this.userInfo.role_ids.split(',').map(x => {
                //         return Number(x)
                //     })
                // } else {
                //     this.selectArray = []
                // }
                // // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", this.selectArray);



            },



            filterFun(val) {
                // // 过滤第一层,第一层中有子集，输出id
                // var a = this.power.filter(x => {
                //     return x.children
                // }).map(x => {
                //     return x.id
                // })

                // // 默认选中数组中,去掉特定的元素
                // this.selectArray = val.filter(x => {
                //     return !a.includes(x)
                // })

                // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", this.selectArray);


                this.formatTree(this.power, 0, val)



            },

            formatTree(tree, val, array) {
                if (!tree || !tree.length) return;

                for (const node of tree) {

                    node.level = val

                    if (node.children && node.children.length) {

                        // 所有有子集的节点
                        this.selectArrayID.push(node.id)

                        // 默认选中数组中,去掉特定的元素
                        this.selectArray = array.filter(x => {
                            return !this.selectArrayID.includes(x)
                        })

                        this.formatTree(node.children, val + 1, array)
                    }

                    // else {
                    //     this.adminTreeArrayOpen.unshift(node.id)
                    //     this.adminTreeArrayOpen = Object.values(this.adminTreeArrayOpen)
                    // }

                }

                // console.log(this.adminTreeArray);

                return tree
            },






            handleCheckChange(data, checked, indeterminate) {
                // console.log(data, checked, indeterminate);

                // if (checked) {
                //     this.selectArray.push(data.id)
                // } else {
                //     this.selectArray.forEach((item, index, arr) => {
                //         if (item === data.id) {
                //             this.selectArray.splice(index, 1);
                //         }
                //     });
                // }
                // console.log("~~~~~~~~~~~~", this.selectArray);
            },

        }
    }
</script>



<style>
    .boxThree {
        /*    border: 1px red dotted;
    box-sizing: content-box; */
        height: 400px;
        overflow: scroll;

    }
</style>